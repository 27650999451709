<template>
  <b-modal
    id="modal-scrollable"
    size="lg"
    scrollable
    :visible="true"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="['bg-primary']"
    :body-class="['scrollbar']"
    content-class="ambassador-terms"
  >
    <template #modal-header>
      <b-row>
        <b-col>
          TERMOS DE USO DA PLATAFORMA VOOMP PARA INTERMEDIAÇÃO DE NEGÓCIOS
        </b-col>
      </b-row>
    </template>

    <template #default>
      <b-row v-if="!loading">
        <b-col cols="12">
          <AmbassadorContract />
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        {{ $t("seller.sales.text_2653") }}
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-modal>
</template>
<script>
import AmbassadorService from "@/services/resources/AmbassadorService";
import AmbassadorContract from "./AmbassadorContract.vue";

const serviceAmbassador = AmbassadorService.build();

export default {
  name: "AmbassadorTerms",
  components: {
    AmbassadorContract,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;

      serviceAmbassador
        .post("terms")
        .then((resp) => {
          this.$store.commit("userSuccess", resp);
          this.$bvModal.hide("ambassador-terms");
          this.$router.push("/comissoes");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(this.$t("seller.sales.text_2654"), {
            title: this.$t("seller.sales.text_2652"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.ambassador-terms {
  padding: 0px !important;

  header {
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 15px 0 !important;
    height: auto;

    .col {
      color: #fff;
      font-size: 0.9rem;
    }
  }

  .bg-primary {
    background-color: #2a63ab !important;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 25px !important;
    height: auto;
  }
}
</style>
