<template>
  <div class="contract">    
    <div v-if="!loading" class="p-4">
      <div
        id="isPasted"
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 7.66667px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O presente instrumento particular&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              outline: transparent solid 1px;
              font-variant-ligatures: none !important;
              background-color: rgb(255, 255, 255);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(“Termos”)&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >regula os direitos e obrigações relacionados à Intermediação de
              Negócios (“</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Contrato</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), sendo os Contratantes, de um lado,</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              outline: transparent solid 1px;
              font-variant-ligatures: none !important;
              background-color: rgb(255, 255, 255);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
            ></span></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
            >
              ANHANGUERA EDUCACIONAL PARTICIPAÇÕES S.A.</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, sociedade anônima, com sede na Alameda Santos, 960, Cerqueira
              César, na cidade de São Paulo, Estado de São Paulo, CEP 01418-002,
              inscrita no CNPJ sob o nº 04.310.392/0010-37, neste ato
              representada de acordo com seu Estatuto Social (doravante
              simplesmente denominada “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >” ou “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Anhanguera</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”) e o intermediador de negócios devidamente identif</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >icado através das informações prestadas à plataforma no momento
              do cadastro, doravante denominado “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":115,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(as partes acima também em conjunto referidas como “</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Partes</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >” e, isoladamente, a “</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Parte</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”)</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONSIDERANDO QUE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a Anhanguera é uma companhia integrante da&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Cogna</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;Educação S.A. (o “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Grupo&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Cogna</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”) que atua no setor de educação e do ensino, oferecendo soluções
              e serviços educacionais para o Ensino Superior e Educação Básica,
              diretamente ou por meio de suas empresas controladas;&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONSIDERANDO QUE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a Anhanguera, através de sua Plataforma Digital&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;(a “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Plataforma Digital</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >” ou “</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”) atua na disposição de soluções tecnológicas para produtores,
              afiliados e potenciais compradores interessados nos conteúdos
              comercializados através das ferramentas disponibilizadas
              na&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONSIDERANDO QUE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;tem interesse em contratar o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;para a prospecção de novos produtores, afiliados e
              potenciais compradores para a utilização da Plataforma&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e da Plataforma&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;(“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Plataforma</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >” ou “</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Plataformas</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”);&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONSIDERANDO QUE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;as Partes desejam firmar um acordo, de acordo com o qual
              o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;realizará a divulgação e intermediação das Plataformas
              visando a prospecção de novos afiliados, produtores e potenciais
              compradores para as Plataformas da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Resolvem as Partes, portanto, celebrar o presente Contrato, que
              será regido de acordo com as seguintes cláusulas e
              condições:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA PRIMEIRA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DO OBJETO&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: left;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >1.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            class=""
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Constitui o objeto do presente Contrato regular os termos e
              condições da divulgação e intermediação, pelo&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, das Plataformas da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e suas principais vantagens, de forma a prospectar novos
              produtores, afiliados e potenciais compradores, em caráter
              eventual e de forma independente, inexistindo qualquer sociedade
              ou vínculo entre elas, exceto pelo presente instrumento.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >1.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Para a consecução do objeto acima, o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;concorda em indicar as Plataformas da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;visando a prospecção e captação de produtores de conteúdo,
              afiliados e potenciais compradores, respeitados os termos deste
              Contrato, mediante a remuneração descrita na&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Cláusula Terceira</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;abaixo.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >1.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes deverão, sempre com a máxima lealdade e dedicação,
              executar e observar todos os termos e condições ora acordados
              sejam nas relações entre si, nas relações com terceiros, aplicando
              o melhor de seus esforços a fim de viabilizar, com êxito, a
              consecução do objeto deste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >1.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Este Contrato não cria nenhum vínculo de exclusividade entre as
              Partes, podendo qualquer delas celebrar negócios semelhantes aos
              assumidos no presente Contrato com terceiros de sua escolha. Não
              obstante o estabelecido acima, o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, neste ato, concorda e se obriga, em caráter irrevogável, a não
              celebrar nenhum contrato ou assumir a obrigação de intermediar a
              oferta de quaisquer produtos e serviços que possam, de qualquer
              maneira, concorrer com os produtos oferecidas pela&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >1.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >5.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O reconhecimento das conversões de novos clientes
              pelo&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;se dará pela formalização via e-mail da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;ao&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner,</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;mediante aceite do Termo de Adesão da
              Plataforma&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, pelo cliente indicado.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >1.6.</span
            ><span
              >Para a execução do objeto destes “Termos” e atingimento dos
              objetivos delineados, o processo ocorrerá da seguinte forma:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 48px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(i)&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >O &nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;deverá realizar o seu cadastro com os dados solicitados na
              Plataforma Digital&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 48px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >ii</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) Após a finalização do referido cadastro, o&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;receberá um e-mail que conterá o link (“Link de
              Voomp Partner”) para que realize a divulgação/indicação das
              Plataformas; um selo constante na Plataforma indicará que a sua
              habilitação foi concluída com sucesso;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 48px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >iii</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) Todo e qualquer&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              font-style: italic;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >seller</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;cadastrado através do "Link de Voomp Partner" será
              contabilizado para fins de comissionamento do</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;Voomp Partner.</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;Tal pagamento será realizado diretamente pela plataforma na
              conta indicada&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlAdvancedProofingIssueV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIycHgiIHZpZXdCb3g9IjAgMCAxMCAyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aW5zaWdodF90ZXh0dXJlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9Imluc2lnaHRfdGV4dHVyZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTItQ29weSI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIj48L3JlY3Q+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLDEgTDUsMSIgaWQ9IkxpbmUtNCIgc3Ryb2tlPSIjNzE2MEU4IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >pelo mesmo</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;no momento de seu cadastro;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 48px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >(</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >iv</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) Para acompanhamento do</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;quanto à contabilização do comissionamento, este deverá
              acessar na Plataforma, a área de "Minhas Comissões" e clicar em
              "Meus Indicados", ali estará disposta a lista de todos os
              indicados e os respectivos valores de comissão;&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >Na</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;área de extrato também são exibidos os valores das
              comissões junto com as vendas (se houver).</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":720,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >1.7.</span
            >O </span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;deverá realizar o seu cadastro com veracidade e exatidão
              das informações prestadas, e reconhece que, caso seja constatado
              que as informações fornecidas quando do seu cadastro são
              incorretas ou inverídicas, seu acesso ao programa poderá ser
              cancelado, independentemente de qualquer formalidade, sem que nada
              seja devido pela</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >. O&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >declara ainda ciência de que se as informações inerentes a sua
              conta bancária forem informadas de maneira incompleta ou incorreta
              poderá haver prejuízo no recebimento de sua retribuição.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":240}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 18px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA SEGUNDA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DAS OBRIGAÇÕES DAS PARTES</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Pelo presente Contrato, o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;se obriga perante a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >realizar a divulgação, apresentar as características e principais
              vantagens das Plataformas da&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >efetuar a intermediação dos entendimentos entre a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e os potenciais novos produtores, afiliados e potenciais
              compradores, incluindo a participação em reuniões entre as
              partes;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >auxiliar a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;durante todas as negociações com os potenciais novos
              produtores, afiliados e potenciais compradores, objetivando a
              conclusão da inclusão destes como novos usuários dos produtos
              da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, de modo a viabilizar o fornecimento e uso das Plataformas por
              eles;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >informar prontamente à&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;sobre o início de negociações ou oportunidades de negócios
              com os&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >potenciais novos produtores, afiliados e potenciais
              compradores</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, devendo referida comunicação conter, no mínimo, as seguintes
              informações: nome do potencial&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >novo produtor, afiliado e potencial comprador</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, endereço, nome e cargo do representante legal (se for o caso) e
              e-mail de contato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >2.2.</span
            ><span
              >Sem prejuízo do cumprimento das obrigações dispostas no item
              supra “2.1”, é defeso ao&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;apresentar-se como representante dos especialistas, dos
              sócios, das marcas e do Grupo&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Cogna</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;sob qualquer circunstância, podendo este tão somente
              cumprir com o objeto contratual deste Termo, em consonância com as
              regras dispostas neste instrumento.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class=""
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Pelo presente Contrato, a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;se obriga perante o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >disponibilizar todos os materiais de divulgação das Plataformas
              providas pela&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, de modo a possibilitar a divulgação e promoção das Plataformas
              aos potenciais novos produtores, afiliados e/ou potenciais
              compradores, nos termos deste Contrato;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >realizar todas as reuniões técnicas com o&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, disponibilizando as informações necessárias para apresentação
              completa das Plataformas, de modo a viabilizar a prestação correta
              de informações sobre as Plataformas e o fechamento do contrato
              entre a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e os potenciais novos produtores, afiliados e/ou potenciais
              compradores.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Analisar o perfil dos potenciais novos produtores, afiliados e/ou
              potenciais compradores informados pelo&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;na forma da Cláusula 2.1., alínea “d”, para fins de
              aprovação ou recusa justificada de seu perfil.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >realizar o pagamento da retribuição financeira do&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, de acordo com as condições estabelecidas na&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Cláusula Terceira</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;abaixo.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Pelo presente Contrato, nenhuma das Partes confere poderes de
              representação à outra Parte, ficando cada uma delas impedida de
              firmar quaisquer compromissos perante terceiros. O&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;será a responsável, exclusivamente e de acordo com as
              condições ora estabelecidas, pela intermediação entre
              a&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e os potenciais novos produtores, afiliados e/ou potenciais
              compradores, sendo de responsabilidade única e exclusiva
              da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;aprovar a celebração de quaisquer contratos com referidos
              potenciais&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >novos produtores, afiliados e/ou potenciais compradores, de
              acordo com suas políticas aplicáveis.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >5.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Cada Parte se obriga a assumir e recolher todos os encargos
              previdenciários e trabalhistas de seus respectivos empregados que
              possam decorrer do presente Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >6.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes reconhecem e concordam que as atividades a serem
              realizadas nos termos deste Contrato não constituirão atividade de
              agência, considerando seu caráter eventual e os demais termos e
              condições deste instrumento.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >7.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Todos os tributos, custos e despesas incorridas por qualquer das
              Partes durante a vigência deste Contrato deverão ser suportados
              exclusivamente pela Parte que incorreu em tais custos e despesas,
              exceto se a assunção de algum custo tiver sido previamente e
              expressamente acordada entre as Partes.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
        class="mt-3"
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA TERCEIRA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DA REMUNERAÇÃO E DESPESAS</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >3.1.</span
            ><span
              >Em retribuição pela intermediação de negócios pactuada neste
              instrumento, o&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >fará jus ao comissionamento, durante o prazo de vigência deste
              Contrato, contados a partir da primeira transação financeira
              realizada por cliente indicado pelo&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, sobre o qual incidirá a taxa de processamento de pagamento
              da&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, conforme diretrizes abaixo especificadas:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >3.1.1.</span
            ><span
              >O percentual de comissionamento será calculado através de uma
              fórmula que considera 5 (cinco) variáveis, a seguir
              disposta:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >C = CB&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >* &nbsp;(</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >TN - TP) *100</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <div
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 2px 0px 2px -5px;
            padding: 0px;
            user-select: text;
            overflow: visible;
            position: relative;
            display: flex;
            justify-content: flex-start;
          "
        >
          <div
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              position: absolute;
              visibility: hidden;
              z-index: -100;
            "
          >
            <br />
          </div>
          <center>
            <table
              border="1"
              data-tablestyle="Custom"
              data-tablelook="0"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                table-layout: fixed;
                width: 0px;
                border-collapse: collapse;
                empty-cells: show;
                position: relative;
                overflow: visible;
                background: transparent;
                border-spacing: 0px;
                width: 100%;
              "
              class=""
            >
              <tbody
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                class=""
              >
                <tr
                  style="
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    overflow: visible;
                    height: 20px;
                  "
                >
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: rgb(217, 217, 217);
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        height: 10px;
                        width: 15px;
                        cursor: pointer;
                        display: flex;
                        overflow: visible;
                        position: absolute;
                        z-index: 5;
                        bottom: -5.5px;
                        left: -11.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        cursor: pointer;
                        display: inline-block;
                        height: 12px;
                        left: auto;
                        overflow: visible;
                        position: absolute;
                        text-align: center;
                        top: -8.5px;
                        width: 20px;
                        z-index: 4;
                        right: -10.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: center;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              color: windowtext !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                              font-weight: bold;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >SIGLA</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335551550":2,"335551620":2,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: rgb(217, 217, 217);
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        cursor: pointer;
                        display: inline-block;
                        height: 12px;
                        left: auto;
                        overflow: visible;
                        position: absolute;
                        text-align: center;
                        top: -8.5px;
                        width: 20px;
                        z-index: 4;
                        right: -10.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: center;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              color: windowtext !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                              font-weight: bold;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >LEGENDA</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335551550":2,"335551620":2,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  style="
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    overflow: visible;
                    height: 20px;
                  "
                >
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        height: 10px;
                        width: 15px;
                        cursor: pointer;
                        display: flex;
                        overflow: visible;
                        position: absolute;
                        z-index: 5;
                        bottom: -5.5px;
                        left: -11.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >TN</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >Taxa Negociada</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  style="
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    overflow: visible;
                    height: 20px;
                  "
                >
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        height: 10px;
                        width: 15px;
                        cursor: pointer;
                        display: flex;
                        overflow: visible;
                        position: absolute;
                        z-index: 5;
                        bottom: -5.5px;
                        left: -11.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >TP</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >Taxa Piso</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  style="
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    overflow: visible;
                    height: 20px;
                  "
                >
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        height: 10px;
                        width: 15px;
                        cursor: pointer;
                        display: flex;
                        overflow: visible;
                        position: absolute;
                        z-index: 5;
                        bottom: -5.5px;
                        left: -11.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >CB</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >Comissão Base</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  style="
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    overflow: visible;
                    height: 20px;
                  "
                >
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        height: 10px;
                        width: 15px;
                        cursor: pointer;
                        display: flex;
                        overflow: visible;
                        position: absolute;
                        z-index: 5;
                        bottom: -5.5px;
                        left: -11.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >C</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    data-celllook="69905"
                    style="
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      overflow: visible;
                      vertical-align: top;
                      position: relative;
                      background-color: transparent;
                      background-clip: padding-box;
                      border: 1px solid rgb(0, 0, 0);
                      width: 300px;
                    "
                  >
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        cursor: pointer;
                        overflow: visible;
                        position: absolute;
                        width: 7px;
                        z-index: 1;
                        height: calc(100% + 1px);
                        left: -4px;
                        top: -0.5px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        top: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        background-repeat: repeat;
                        bottom: 0px;
                        overflow: visible;
                        position: absolute;
                        top: -0.5px;
                        width: 7px;
                        z-index: 3;
                        height: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/hD55E5E9C2AD2E4F5_resources/1046/ColResize.cur'),
                          pointer;
                        right: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: -3px 0px 0px;
                        padding: 0px;
                        user-select: text;
                        background-color: transparent;
                        background-repeat: repeat;
                        height: 7px;
                        overflow: visible;
                        position: absolute;
                        z-index: 3;
                        left: -0.5px;
                        width: calc(100% + 1px);
                        cursor: url('https://res-1.cdn.office.net:443/officeonline/we/s/h1E5273DBAA04AEF6_resources/1046/RowResize.cur'),
                          pointer;
                        bottom: -4px;
                      "
                    >
                      <br />
                    </div>
                    <div
                      style="
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        margin: 0px;
                        padding: 6px;
                        user-select: text;
                        overflow: visible;
                      "
                    >
                      <div
                        style="
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          clear: both;
                          cursor: text;
                          overflow: visible;
                          position: relative;
                          direction: ltr;
                        "
                      >
                        <p
                          style="
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            overflow-wrap: break-word;
                            white-space: pre-wrap;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            data-contrast="auto"
                            lang="PT-BR"
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-variant-ligatures: none !important;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            ><span
                              style="
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                              "
                              >Comissão Efetiva</span
                            ></span
                          ><span
                            data-ccp-props='{"134245417":false,"201341983":0,"335559740":240}'
                            style="
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              font-size: 11pt;
                              line-height: 16px;
                              font-family: 'Arial Narrow',
                                'Arial Narrow_EmbeddedFont',
                                'Arial Narrow_MSFontService', sans-serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </div>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >3.1.2.</span
            ><span
              >Na hipótese de renegociação das condições comerciais dos clientes
              indicados pelo&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;que implique em taxa transacional inferior à taxa piso,
              à&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;é resguardada a faculdade de cessar o pagamento da comissão
              por divulgação e intermediação comercial ao&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >3.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Os valores de comissionamento serão pagos diretamente pela
              Plataforma&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Voomp</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Creators</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e, por esta razão, o Voomp Partner, ao solicitar o saque dos
              valores de&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >comissionamento, &nbsp;se</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;obriga à emissão da respectiva Nota Fiscal, bem como o
              envio da respectiva Nota Fiscal para o e-mail
              “admin@voomp.com.br”, com antecedência mínima de 5 (cinco) dias da
              data avençada para o pagamento.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360,"469777462":[567],"469777927":[0],"469777928":[1]}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360,"469777462":[567],"469777927":[0],"469777928":[1]}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >3.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O presente Contrato não gera nenhum outro direito ao&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;além do recebimento da Retribuição estipulada nesta
              cláusula. O&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;declara que referida Retribuição é justa e contrapartida
              suficiente para as atividades de divulgação e intermediação
              assumidas pelo&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;sob este Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >3.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Todos os tributos, custos e despesas incorridas por qualquer das
              Partes durante a vigência deste Contrato deverão ser suportados
              exclusivamente pela Parte que incorreu em tais custos e despesas,
              exceto se a assunção de algum custo tiver sido previamente e
              expressamente acordada entre as Partes.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
        class="mt-4"
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA QUARTA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DO PRAZO E RESCISÃO</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: left;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >4.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Este Contrato vigorará pelo período</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;de 06 (seis) meses, contados a partir da data de aceite
              (marcação do&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >checkbox</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) e/ou adesão do&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;a este instrumento, podendo ser prorrogado, m</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >ediante instrumento próprio, pelo tempo que as Partes melhor
              convencionarem.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >4.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As partes poderão rescindir o presente Contrato nas seguintes
              hipóteses:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >automaticamente, sem a necessidade de qualquer notificação ou
              aviso prévio, no caso de descumprimento de qualquer cláusula,
              condição, declaração ou garantia aqui convencionada;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >automaticamente no caso de requerimento, decretação ou
              deferimento de falência, recuperação judicial ou extrajudicial ou
              liquidação judicial ou extrajudicial de uma das
              Partes;&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >automaticamente, caso o&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;se envolva em situações que possam desabonar o nome, a
              imagem, a boa fama e/ou a honra da&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >ocorrência de caso fortuito ou força maior, devidamente
              comprovados, impeditivos da execução das obrigações do presente
              contrato;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(e)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >de forma imotivada, por qualquer das partes, com aviso prévio de
              90 (noventa) dias, não implicando a rescisão imotivada em qualquer
              direito a qualquer das Partes em juízo ou fora dele.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >4.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Uma vez realizada a rescisão do Contrato por qualquer das Partes,
              todos os direitos e obrigações estabelecidos neste Contrato
              deverão ser considerados imediatamente rescindidos, com exceção
              daquelas que, por sua natureza ou disposição contratual, devam
              permanecer em vigor.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >4.4.</span
            ><span>A&nbsp;</span></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;se resguarda o direito de cancelar ou suspender o programa
              de&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp PartnerES&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >a qualquer tempo, sem necessidade de formalização ou aviso
              prévio, e sem que nada seja devido&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlAdvancedProofingIssueV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIycHgiIHZpZXdCb3g9IjAgMCAxMCAyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aW5zaWdodF90ZXh0dXJlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9Imluc2lnaHRfdGV4dHVyZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikdyb3VwLTItQ29weSI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIj48L3JlY3Q+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLDEgTDUsMSIgaWQ9IkxpbmUtNCIgc3Ryb2tlPSIjNzE2MEU4IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >pela mesma</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, em razão de tal cancelamento e suspensão.&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >4.5.</span
            ><span>A&nbsp;</span></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;poderá cancelar ou suspender a conta do&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;como medida de proteção à “Plataforma”, ao&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;e a terceiros, caso tenha indícios de roubo de identidade,
              utilização/apropriação de meios de pagamento de forma ilícita,
              desvio da finalidade e outras atividades fraudulentas conforme
              disposições deste “Termo” e eventuais anexos, sem que nada seja
              devido pela&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >&nbsp;,</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;em razão de tal cancelamento e suspensão.&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >4.6.</span
            ><span>A&nbsp;</span></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >&nbsp; poderá</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, a qualquer momento, a seu exclusivo critério e sem a
              necessidade de prévio aviso, excluir&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >conteúdos</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;que forem produzidos, disponibilizados, divulgados,
              compartilhados ou transmitidos pelo&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;que estejam em desacordo com as regras dispostas neste
              Termo, bem como a legislação aplicável.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA QUINTA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DAS DECLARAÇÕES E GARANTIAS</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >5.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes neste ato declaram que:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >são sociedades devidamente constituídas, legalmente existentes e
              em situação regular e devidamente licenciadas e habilitadas a
              operar, possuindo todos os livros e documentos societários
              exigidos pela legislação em vigor para o exercício de suas
              atividades;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >tem todos os poderes e as capacidade necessárias para celebrar o
              presente Contrato e cumprir com as obrigações aqui
              estabelecidas;&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >este Contrato constitui sua obrigação legal, válida e vinculante,
              exequível contra si de acordo com seus termos e condições;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >a celebração e cumprimento deste Contrato não deverá (i) violar
              qualquer lei aplicável a qualquer um deles ou qualquer disposição
              de seus documentos sociais, no caso de pessoas jurídicas; ou
              (</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >ii</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) resultar na violação ou vencimento antecipado de qualquer
              contrato a que seja parte.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA SEXTA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DA CONFIDENCIALIDADE</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes, por si, seus representantes, colaboradores,
              empregados, prepostos e/ou prestadores de serviços (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Pessoas Autorizadas</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), concordam em manter sigilo sobre toda e qualquer informação
              divulgada por uma Parte a outra, por meio escrito ou oral (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Informações Confidenciais</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), com estrita observância das leis e regras de propriedade
              intelectual aplicáveis ao caso.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Informações Confidenciais podem ser divulgadas pela Parte
              receptora às Pessoas Autorizadas, empresas do mesmo grupo ou
              coligadas, desde que os receptores aceitem e cumpram todos os
              termos deste Contrato, ficando a Parte a que eles estão vinculados
              responsável pelo eventual descumprimento das obrigações previstas
              neste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            class="mr-3"
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Não serão consideradas Informações Confidenciais e, portanto,
              poderão ser divulgadas, as informações que:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 18px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >já sejam conhecidas pela Parte Receptora antes da execução deste
              Contrato;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":283,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 18px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >sejam de domínio público (veiculada pela imprensa, mídia ou
              qualquer outro meio de comunicação ao público) de outra forma que
              não como resultado direto ou indireto de divulgação feita pelas
              Partes;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":283,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 13.3333px 18px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >devam ser divulgadas nos termos da legislação aplicável ou por
              determinação da Administração Pública, ou por decisão judicial ou
              arbitral; ou</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":283,"335559739":200,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 18px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >tenham sido adquiridas ou desenvolvidas por uma Parte, sem
              violação às obrigações previstas neste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":283,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px 0px 0px 18px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559685":283,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >A Parte Receptora deve apenas utilizar ou permitir a utilização
              das Informações Confidenciais para o desenvolvimento dos estudos
              realizados no âmbito deste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >5.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >A Parte Receptora deve ser responsável por assegurar que todas as
              pessoas a quem as Informações Confidenciais sejam divulgadas
              cumpram as obrigações e termos deste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >6.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Informações Confidenciais permanecerão de propriedade da Parte
              reveladora, que pode exigir sua devolução a qualquer tempo,
              mediante aviso escrito à Parte receptora. No prazo de 30 (trinta)
              dias do recebimento da notificação a Parte receptora deverá
              devolver todas as vias originais das Informações Confidenciais e
              deverá destruir todas as cópias em sua posse e em posse de seus
              representantes.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >6.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >7.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As obrigações de confidencialidade previstas nesta Cláusula
              perdurarão durante o prazo de vigência deste Contrato e perdurarão
              por um período de 2 (dois) anos contados do fim da vigência deste
              instrumento.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA SÉTIMA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >PROPRIEDADE INTELECTUAL</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >7.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Fica expressamente vedado a cada uma das Partes fazer uso do nome
              comercial, marca ou logotipo da outra Parte de qualquer forma ou
              por qualquer motivo, salvo para os fins do presente Contrato, bem
              como mediante prévio e expresso consentimento da Parte titular,
              sob pena da Parte que infringir esta Cláusula responder por
              violação de propriedade intelectual e perdas e danos decorrentes
              do seu uso indevido.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >7.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes autorizam a divulgação do presente instrumento e do seu
              objeto interna e externamente, com a utilização das marcas, em
              seus materiais publicitários e promocionais impressos e digitais,
              para fomento da intermediação dos negócios e para o
              desenvolvimento do objeto deste Contrato.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >7.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes têm ciência e reconhecem que todo software, logotipo,
              marcas, sinais distintivos, conteúdos e demais materiais
              relacionados, aos quais a outra Parte venha a ter acesso e/ou
              conhecimento no curso deste instrumento, constituem
              respectivamente propriedade de cada uma das Partes, suas
              respectivas controladoras ou eventuais terceiros por ela
              contratados, sendo protegidos por legislação específica.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >7.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Fica estabelecido que nada neste instrumento será interpretado
              como transferência, cessão ou concessão de qualquer tipo de
              licença relativa aos direitos de propriedade intelectual não
              pactuados no presente, em especial no que tange aos conteúdos
              divulgados pela&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;através das ferramentas disponibilizadas nas
              Plataformas.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA OITAVA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DA DESVINCULAÇÃO E NÃO SOLIDARIEDADE</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >8.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Não se estabelecerá, por força deste Contrato, para nenhum
              efeito, nenhum tipo de sociedade, associação,&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              font-style: italic;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >joint venture</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, agência, consórcio, mandato de representação ou
              responsabilidade solidária entre as Partes aqui contratantes,
              tampouco enseja este Contrato qualquer vínculo operacional,
              gerencial ou de qualquer outra natureza entre as Partes.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >8.2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Não se estabelece, por força deste Instrumento, direta ou
              indiretamente, qualquer vínculo empregatício, obrigação, ou
              responsabilidade entre uma Parte com a outra com relação aos
              profissionais que a outra Parte disponibilizar para a execução do
              objeto deste instrumento, correndo por conta exclusiva de cada
              Parte todos os encargos decorrentes de legislação vigente sob seus
              próprios colaboradores, seja trabalhista, previdenciária,
              securitária, civil ou quaisquer outros que vierem a ser criados
              pelos órgãos p</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >úblicos, sindicatos e entidades representativas das
              categorias.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >8.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;será a única responsável por todo e qualquer ato ou
              omissão, relacionados a este Contrato, que possam gerar
              responsabilidade de natureza civil, criminal, tributária,
              trabalhista, previdenciária ou ambiental em decorrência dos
              serviços, com exclusão de toda a responsabilidade, ainda que
              subsidiária, da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, arcando com todos os custos, indenizações e compensações
              decorrentes de sua responsabilidade.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA NONA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DAS OBRIGAÇÕES ANTICORRUPÇÃO</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >9.1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Para fins do cumprimento do objeto deste Contrato, as Partes
              asseguram que, de nenhum modo, violarão ou concorrerão para a
              violação de qualquer legislação nacional e/ou internacional de
              prevenção à corrupção, lavagem de dinheiro, fraudes a licitações e
              contratos administrativos e condutas assemelhadas, incluindo, sem
              limitação, as Lei nº 8.429, de 1992 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Lei de Improbidade Administrativa</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), Lei nº 8.666/1993 e Lei nº 14.133 de 2021 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Lei de Licitações</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), Lei nº 9.613/1998 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Lei de Lavagem de Dinheiro</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”) e a Lei nº 12.846, de 2013 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Lei Anticorrupção</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), e, ainda, as Leis Práticas de Corrupção no Exterior dos
              Estados Unidos da América, de 1977 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Foreign</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Corrupt</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Practices</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Act</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;– FCPA</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), e de Suborno do Reino Unido, de 2010 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >United Kingdom&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Bribery</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Act</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;– UKBA</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”), todas em conjunto, denominadas como “</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Legislação Anticorrupção</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >” e, em especial, se comprometem a não prometer, oferecer ou dar,
              direta ou indiretamente, vantagem indevida ou qualquer coisa de
              valor a agente público, ou a terc</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >eira pessoa a ele relacionada, no que respeita ao cumprimento do
              objeto deste Contrato ou qualquer outra relação envolvendo as
              Partes, para qualquer fim ou efeito.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >9.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Declaram, ainda, sem limitação, que:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >não financiam, custeiam, patrocinam ou de qualquer modo
              subvencionam a prática dos atos ilícitos previstos na Legislação
              Anticorrupção;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >não prometem, oferecem ou dão, direta ou indiretamente, vantagem
              indevida ou quaisquer itens de valor a agentes públicos ou a
              terceiros para obter ou manter negócios ou para obter qualquer
              vantagem imprópria;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >em todas as suas atividades relacionadas a este instrumento,
              cumprirão, a todo tempo, com todos os regulamentos e legislações
              aplicáveis; e</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(d)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >envidarão seus melhores esforços para implementar programa
              próprio de integridade visando garantir o cumprimento da
              Legislação Anticorrupção.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >9.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >declara e garante que:</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(a)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >leu e está inteiramente ciente do Código de Conduta no que for
              aplicável e Política Anticorrupção da&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Cogna</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;Educação S.A., controladora da&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, de conhecimento da Parte e/ou disponível para leitura no site
              eletrônico:&nbsp;</span
            ></span
          ><a
            href="http://about:blank/"
            target="_blank"
            rel="noreferrer noopener"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              cursor: text;
              text-decoration: none;
              color: inherit;
            "
            ><span
              data-contrast="none"
              lang="PT-BR"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                color: rgb(0, 0, 255);
                font-size: 12pt;
                text-decoration: underline;
                line-height: 27px;
                font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                  'Arial Narrow_MSFontService', sans-serif;
              "
              ><span
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >www.cogna.com.br</span
              ></span
            ></a
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(b)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Comunicará à&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CONTRATANTE</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, por escrito, qualquer violação ou suspeita de violação à
              Legislação Anticorrupção, por meio do Canal
              Confidencial&nbsp;</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >Cogna</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;disponibilizado pela CONTRATANTE, que pode ser acessado no:
              (i) website</span
            ></span
          ><a
            href="https://canalconfidencial.com.br/cognaedu/"
            target="_blank"
            rel="noreferrer noopener"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              cursor: text;
              text-decoration: none;
              color: inherit;
            "
            ><span
              data-contrast="none"
              lang="PT-BR"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                color: rgb(0, 0, 255);
                font-size: 12pt;
                text-decoration: underline;
                line-height: 27px;
                font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                  'Arial Narrow_MSFontService', sans-serif;
              "
              ><span
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >&nbsp;https://canalconfidencial.com.br/cognaedu/</span
              ></span
            ></a
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >; e (</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >ii</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) telefone 0800 741 0018; e</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >(c)</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Cooperará integralmente com qualquer investigação que a
              CONTRATANTE pretenda conduzir a respeito de violação, potencial ou
              efetiva, da Legislação Anticorrupção.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >9.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >A CONTRATANTE poderá realizar, a qualquer tempo, no prazo de
              vigência deste Contrato, auditoria relacionada ao programa de
              integridade do&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >, a fim averiguar o cumprimento das disposições constantes da
              Legislação Anticorrupção.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: rgb(255, 255, 255);
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335557856":16777215,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >9.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >5.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Na hipótese de uma das Partes entender, de boa-fé, que a outra
              possa estar agindo de forma que possa onerar ou prejudicá-la sob
              os termos da Legislação Anticorrupção, esta parte poderá rescindir
              unilateralmente este Contrato, respondendo a parte infratora por
              quaisquer perdas daí resultantes causadas à parte inocente.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA DÉCIMA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DA PROTEÇÃO DE DADOS</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes, por si e por seus colaboradores, atuarão no presente
              Contrato em conformidade com a Legislação vigente e seus
              dispositivos referentes à Privacidade e a Proteção de Dados
              Pessoais bem como a determinações de órgãos reguladores e
              fiscalizadores sobre a matéria, em especial a Lei Geral de
              Proteção de Dados n° 13.709/18 (“</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >LGPD</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >”).</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes desde já reconhecem e acordam que, para os fins do
              presente Contrato e de acordo com os termos da legislação vigente,
              cada Parte atua como Controladora em relação aos Dados Pessoais
              que coleta e trata, e não se pretende que qualquer Parte atue como
              um Operadora para a outra Parte em relação a qualquer atividade de
              tratamento de referidos Dados. Cada Parte deve garantir que seu
              Tratamento esteja de acordo com a LGPD, inclusive referente a
              aplicação da hipótese de tratamento e atendimento aos direi</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >tos dos Titulares.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class=""
              >10.2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >A cada uma das Partes será conferido o papel de Controladora
              exclusiva dos Dados Pessoais que (i) vier a coletar diretamente
              junto aos respectivos Titulares de Dados Pessoais com os quais já
              tenha relação prévia a este Contrato desde que essa operação se dê
              a partir de seus próprios desígnios; e, (</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >ii</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) bem como dos Dados Pessoais cujo domínio lhes seja exclusivo em
              relação ao desempenho de suas atividades regulares.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >É dever das Partes assegurar medidas de segurança administrativas
              e tecnológicas razoáveis e compatíveis em relação à natureza dos
              Dados Pessoais que tratar, com especial zelo para eventuais
              tratamentos de Dados Sensíveis ou de Crianças e Adolescentes e
              mediante certificação de que apenas seu pessoal autorizado terá
              acesso a tais informações em regime de sigilo. Em caso da
              necessidade de reportar incidentes conforme a LGPD, é dever de uma
              Parte notificar em prazo razoável a outra Parte acerca dos
              fenômenos</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;apurados, sendo que se houver a dificuldade de prestar
              integralmente as informações exigíveis por lei, ela deverá fazê-lo
              de forma gradativa segundo o apurado.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.2.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Na eventual necessidade estrita de uma Parte conduzir operações
              promocionais de e-mail marketing para a promoção de suas
              atividades e interesses relacionados a este Contrato, será
              assegurado sempre o direito de saída ou descadastro (</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              font-style: italic;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >opt-out</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >) aos destinatários, avalizada ainda a existência de base legal
              para tais operações, como o consentimento e/ou o interesse
              legítimo, balanceado neste último caso as garantias e direitos dos
              titulares e vedados tratamentos de dados sensíveis.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Para fins deste instrumento, todos os termos iniciados em letras
              maiúsculas e não definidos nesse instrumento terão o significado a
              eles atribuídos na LGPD, devendo ser observando ainda pelas Partes
              os demais componentes deste instrumento, e ainda, a Política de
              Privacidade da CONTRATANTE, disponibilizada no Portal de
              Privacidade através do seguinte&nbsp;</span
            ></span
          ><a
            href="https://www.canaldatransparencia.com.br/cogna/#politica"
            target="_blank"
            rel="noreferrer noopener"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              cursor: text;
              text-decoration: none;
              color: inherit;
            "
            ><span
              data-contrast="none"
              lang="PT-BR"
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                font-variant-ligatures: none !important;
                color: rgb(0, 0, 255);
                font-size: 12pt;
                text-decoration: underline;
                line-height: 27px;
                font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                  'Arial Narrow_MSFontService', sans-serif;
              "
              ><span
                style="
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                "
                >https://www.canaldatransparencia.com.br/cogna/#politica</span
              ></span
            ></a
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >10.4.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes poderão compartilhar documentos de atos constitutivos,
              bem como quaisquer outras informações que sejam necessárias para
              fins da formalização do presente instrumento, e reconhecem que,
              não obstante a possível existência em tais documentos de Dados
              Pessoais, de seus procuradores e/ou representantes legais, o
              Tratamento de tais Dados Pessoais pela Parte contrária é
              necessário para que este possa confirmar a qualificação dos
              signatários deste instrumento, bem como para verificação de
              antecedentes e re</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >alização de auditoria legal relacionada a esta contratação, e
              ainda, a Anhanguera poderá utilizar os dados pessoais para
              diversas finalidades relacionadas ao desempenho de suas
              atividades, tais como, mas não se limitando ao atendimento de
              requisições de autoridades administrativas e judiciais; exercício
              regular de direitos, inclusive em processos administrativos,
              judiciais e arbitrais; análise, gerenciamento e tratamento de
              potenciais riscos, incluindo os de fraude e segurança;
              verificação, análise e tratam</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >ento de dados pessoais para fins de avaliação, manutenção e
              aprimoramento de homologação de fornecedores e prestadores de
              serviços; hipóteses de legítimo interesse.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >10.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >5.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
          ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Fica assegurado à CONTRATANTE, nos termos da lei, o direito de
              regresso em face do Voomp Partner diante de eventuais danos causados
              por esta em decorrência do descumprimento das obrigações aqui
              assumidas em relação a Proteção dos Dados.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >CLÁUSULA DÉCIMA PRIMEIRA</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: center;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >DAS CONDIÇÕES GERAIS</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":2,"335551620":2,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >11.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >1.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O presente Contrato constitui o único acordo que regula os
              direitos e obrigações das Partes em relação ao seu objeto. Nada
              obstante, o presente Contrato não cancelará, revogará ou tornará
              sem efeito eventual acordo entre as Partes já celebrado quanto ao
              mesmo objeto deste Contrato, desde que o seja feito de maneira
              específica, em razão de sua especialidade.&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >11.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >2.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Qualquer tolerância por uma das Partes em relação ao cumprimento
              das obrigações pela outra Parte, não será considerada novação ou
              renúncia, permanecendo as cláusulas deste Contrato em pleno vigor
              e efeito, na forma aqui prevista.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >11.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >3.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Se qualquer disposição deste Contrato for determinada
              judicialmente inválida ou ineficaz, tal determinação não gerará
              efeitos em qualquer outra disposição pelo que as demais cláusulas
              remanescentes permanecerão válidas e eficazes.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >11.4.</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >O presente “Contrato” poderá ser modificado e/ou alterado
              periodicamente, integralmente ou em parte, pela PRODUTORA,
              mediante prévia comunicação ao USUÁRIO.&nbsp;</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              class="mr-3"
              >11.5.</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Da adesão:&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Com a marcação do "</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlSpellingErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDUgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTYuMiAoODE2NzIpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPnNwZWxsaW5nX3NxdWlnZ2xlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IkZsYWdzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTAxMC4wMDAwMDAsIC0yOTYuMDAwMDAwKSIgaWQ9InNwZWxsaW5nX3NxdWlnZ2xlIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAxMC4wMDAwMDAsIDI5Ni4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDMgQzEuMjUsMyAxLjI1LDEgMi41LDEgQzMuNzUsMSAzLjc1LDMgNSwzIiBpZD0iUGF0aCIgc3Ryb2tlPSIjRUIwMDAwIiBzdHJva2Utd2lkdGg9IjEiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjQiPjwvcmVjdD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+')
                );
                border-bottom: 1px solid transparent;
              "
              >check</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;box" ao final do presente documento ou com o
              prosseguimento/atuação do&nbsp;</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="auto"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;como tal na Plataforma, o</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >Voomp Partner</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >&nbsp;expressa ter lido e compreendido os presentes Termos e os
              demais instrumentos jurídicos mencionados e disponibilizados,
              assumindo os direitos e obrigações estabelecidos, aceitando os
              presentes Termos para todos os fins de direito.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
      <div
        style="
          -webkit-user-drag: none;
          -webkit-tap-highlight-color: transparent;
          margin: 0px;
          padding: 0px;
          user-select: text;
          clear: both;
          cursor: text;
          overflow: visible;
          position: relative;
          direction: ltr;
          color: rgb(0, 0, 0);
          font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-variant-ligatures: normal;
          font-variant-caps: normal;
          font-weight: 400;
          letter-spacing: normal;
          orphans: 2;
          text-align: start;
          text-indent: 0px;
          text-transform: none;
          widows: 2;
          word-spacing: 0px;
          -webkit-text-stroke-width: 0px;
          white-space: normal;
          background-color: rgb(255, 255, 255);
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
        "
      >
        <p
          style="
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            margin: 0px;
            padding: 0px;
            user-select: text;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-weight: normal;
            font-style: normal;
            vertical-align: baseline;
            font-kerning: none;
            background-color: transparent;
            color: windowtext;
            text-align: justify;
            text-indent: 0px;
          "
        >
          <span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >11.</span
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                background-repeat: repeat-x;
                background-position: left bottom;
                background-image: var(
                  --urlContextualSpellingAndGrammarErrorV2,
                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                );
                border-bottom: 1px solid transparent;
              "
              class="mr-3"
              >6.</span
            ></span
          ><span
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              background-repeat: repeat-x;
              background-position: left bottom;
              display: inline-block;
              font-family: Calibri, sans-serif;
              font-size: 12pt;
              font-style: normal;
              font-weight: 400;
              position: relative;
              text-indent: 0px;
              white-space: nowrap;
              background-image: var(
                --urlContextualSpellingAndGrammarErrorV2,
                url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
              );
              border-bottom: 1px solid transparent;
              text-align: left;
              width: 0px;
              color: rgb(38, 38, 38);
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
                white-space: pre !important;
                display: inline-block;
              "
              >&nbsp; &nbsp;&nbsp;</span
            ></span
          ><span
            data-contrast="none"
            lang="PT-BR"
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-variant-ligatures: none !important;
              color: rgb(38, 38, 38);
              font-size: 12pt;
              text-decoration: underline;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              font-weight: bold;
            "
            ><span
              style="
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: transparent;
                margin: 0px;
                padding: 0px;
                user-select: text;
              "
              >As Partes elegem o foro da Comarca de São Paulo, Estado de São
              Paulo, como o único competente para dirimir quaisquer disputas
              oriundas do presente Contrato, com a expressão exclusão de
              qualquer outro, por mais privilegiado que seja.</span
            ></span
          ><span
            data-ccp-props='{"201341983":0,"335551550":6,"335551620":6,"335559740":360}'
            style="
              -webkit-user-drag: none;
              -webkit-tap-highlight-color: transparent;
              margin: 0px;
              padding: 0px;
              user-select: text;
              font-size: 12pt;
              line-height: 27px;
              font-family: 'Arial Narrow', 'Arial Narrow_EmbeddedFont',
                'Arial Narrow_MSFontService', sans-serif;
              color: rgb(38, 38, 38);
            "
            >&nbsp;</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmbassadorContract",
  data() {
    return {};
  },
};
</script>

<style>
.contract {
  padding: 0 2rem;
  text-align: justify;

  h1 {
    font-size: 1rem;
    margin-bottom: 1.8rem;
    text-align: center;
    font-weight: bold;
  }

  h2 {
    font-size: 1rem;
    margin: 1.6rem 0;
    text-align: center;
    font-weight: bold;
  }

  p {
    font-weight: normal;

    &.sub {
      margin-left: 2rem;
    }

    span.u {
      text-decoration: underline;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.8rem;

    tr {
      border: 1px solid #000;

      th {
        background-color: #f2f2f2;
        text-align: center;
      }

      th,
      td {
        width: 50%;
        border: 1px solid #000;
        padding: 0.5rem;
      }
    }
  }
}
</style>
