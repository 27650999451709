<template>
  <div class="container">
    <!-- modals -->
    <AmbassadorTermsModal />
  </div>
</template>
<script>
import AmbassadorTermsModal from "@/components/Seller/Comissions/modals/AmbassadorTerms";
import Utils from "@/mixins/utils";

export default {
  name: "TermosComissoes",
  mixins: [Utils],
  components: {
    AmbassadorTermsModal,
  },
  data() {
    return {
    };
  },

  mounted() {
    if(this.ambassadorAcceptedTerms) {
      this.$router.push("/comissoes");
    } else {
      this.$bvModal.show("ambassador-terms");
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
